<template>
  <div class="wrap">
    <div class="filter">
      <button @click="handleLeague">
        <span
          >{{ selectedCountry.name || $t("byleague") }}
          <span v-if="checkedTournament.length > 0" class="count">{{
            checkedTournament.length
          }}</span></span
        >
        <span><CaretDown /></span>
      </button>
      <button @click="handleAction('by-time')">
        <span>{{ selectedTime || $t("byTime") }}</span>
        <span><CaretDown /></span>
      </button>
      <button @click="handleAction('by-market')">
        <span>{{ selectedMarket.market_name || $t("byMarket") }}</span>
        <span><CaretDown /></span>
      </button>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="learn_more">
        <div v-if="actionType === 'by-league'" class="filter_contents">
          <p>
            <span>{{ $t("filterByLeague") }}</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>
          <section class="accordion">
            <div
              v-for="(tournaments, country, index) in groupedByCountry"
              :key="country"
            >
              <div
                class="head collapse"
                data-toggle="collapse"
                :data-target="`#collapse-${index + 1}`"
                aria-expanded="false"
                :aria-controls="`collapse-${index + 1}`"
              >
                <span>
                  <!-- <CheckBoxIcon :active="false" /> -->
                  <span>{{ country }}</span>
                </span>
                <span style="float: right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="7"
                    viewBox="0 0 14 7"
                    fill="none"
                  >
                    <path
                      d="M12.28 0.966797L7.9333 5.31346C7.41997 5.8268 6.57997 5.8268 6.06664 5.31346L1.71997 0.966797"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
              <div class="collapse body_contents" :id="`collapse-${index + 1}`">
                <div
                  v-for="tournament in tournaments"
                  :key="tournament.tournament_id"
                  @click="handleSelectedCountry(tournament)"
                >
                  <span>
                    <CheckBoxIcon
                      :active="
                        checkedTournament.includes(tournament.tournament_id)
                      "
                    />
                    <span class="check">{{ tournament.name }}</span></span
                  >
                  <span class="light">({{ tournament.games }}) </span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div v-if="actionType === 'by-time'" class="filter_contents">
          <p>
            <span>{{ $t("filterByDate") }}</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>

          <div v-if="timeCustom" class="custom-time">
            <div>
              <div class="start-date">
                <label for="">{{ $t("start") }}</label>
                <input
                  type="date"
                  v-model="selectedStartDate"
                  @change="handleDateChange(selectedStartDate, 'start')"
                  class="date-picker"
                  placeholder="Date"
                />
              </div>
              <div class="start-date">
                <label for="">{{ $t("end") }}</label>
                <input
                  type="date"
                  v-model="selectedEndDate"
                  @change="handleDateChange(selectedEndDate, 'end')"
                  class="date-picker"
                  placeholder="Date"
                />
              </div>
            </div>
            <ChopbetButton @click="handleSubmitDate" variant="primary">
              {{ $t("done") }}
            </ChopbetButton>
          </div>
          <div v-else>
            <div
              v-for="item in timeFilter"
              :key="item.id"
              class="item"
              @click="handleSelectedTime(item)"
            >
              <span>
                <span>{{ item.name }}</span>
                <span v-if="selectedTime === item.value">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99998 1.33301C4.32665 1.33301 1.33331 4.32634 1.33331 7.99967C1.33331 11.673 4.32665 14.6663 7.99998 14.6663C11.6733 14.6663 14.6666 11.673 14.6666 7.99967C14.6666 4.32634 11.6733 1.33301 7.99998 1.33301ZM11.1866 6.46634L7.40665 10.2463C7.31331 10.3397 7.18665 10.393 7.05331 10.393C6.91998 10.393 6.79331 10.3397 6.69998 10.2463L4.81331 8.35967C4.61998 8.16634 4.61998 7.84634 4.81331 7.65301C5.00665 7.45967 5.32665 7.45967 5.51998 7.65301L7.05331 9.18634L10.48 5.75967C10.6733 5.56634 10.9933 5.56634 11.1866 5.75967C11.38 5.95301 11.38 6.26634 11.1866 6.46634Z"
                      fill="#8F0DCC"
                    />
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-if="actionType === 'by-market'" class="filter_contents">
          <p>
            <span>{{ $t("filterByMarket") }}</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>

          <div
            v-for="item in markets"
            :key="item.id"
            class="item"
            @click="handleSelectedMarket(item)"
          >
            <span>
              <span>{{ item.market_name }}</span>
              <span v-if="selectedMarket.market_id === item.market_id">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 1.33301C4.32665 1.33301 1.33331 4.32634 1.33331 7.99967C1.33331 11.673 4.32665 14.6663 7.99998 14.6663C11.6733 14.6663 14.6666 11.673 14.6666 7.99967C14.6666 4.32634 11.6733 1.33301 7.99998 1.33301ZM11.1866 6.46634L7.40665 10.2463C7.31331 10.3397 7.18665 10.393 7.05331 10.393C6.91998 10.393 6.79331 10.3397 6.69998 10.2463L4.81331 8.35967C4.61998 8.16634 4.61998 7.84634 4.81331 7.65301C5.00665 7.45967 5.32665 7.45967 5.51998 7.65301L7.05331 9.18634L10.48 5.75967C10.6733 5.56634 10.9933 5.56634 11.1866 5.75967C11.38 5.95301 11.38 6.26634 11.1866 6.46634Z"
                    fill="#8F0DCC"
                  />
                </svg>
              </span>
            </span>
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
  </div>
</template>

<script>
import axios from "axios";
import CancelIcon from "../icons/CancelIcon.vue";
import CaretDown from "../icons/CaretDown.vue";
import ChopbetSlideUp from "./ChopbetSlideUp.vue";
import fix from "@/services/fix";
import ChopbetButton from "./ChopbetButton.vue";
import CheckBoxIcon from "@/components/icons/CheckBoxIcon.vue";

export default {
  name: "SportFilters",
  components: {
    CaretDown,
    ChopbetSlideUp,
    CancelIcon,
    ChopbetButton,
    CheckBoxIcon,
  },

  props: {
    actionType: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isSlideUpOpen: false,
      timeCustom: false,
      selectedCountry: {},
      selectedTime: "",
      customType: null,
      selectedMarket: "",
      selectedDate: "",
      selectedEndDate: "",
      selectedStartDate: "",
      competition_name: "Top League",
      league: {},
      countries: [],
      markets: [],
      market: {},
      market_id: 1,
      timeFilter: [
        { id: 1, name: this.$t("all"), value: "all" },
        { id: 2, name: this.$t("today"), value: "today" },
        { id: 3, name: this.$t("tomorrow"), value: "tomorrow" },
        { id: 4, name: this.$t("customDate"), value: "custom date" },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: function () {
      this.getMarket();
      this.getTournaments();
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
      this.timeCustom = false;
    },
    handleSelectedCountry(country) {
      const index = this.checkedTournament.indexOf(country.tournament_id);
      if (index > -1) {
        this.checkedTournament.splice(index, 1);
      } else {
        this.checkedTournament = [
          ...this.checkedTournament,
          country.tournament_id,
        ];
      }
    },
    handleSelectedMarket(market) {
      this.setMarket(market);
      this.selectedMarket = market;
      const filters = {
        ...this.$store.state.market_filters,
        byMarket: market.market_id,
      };
      this.$store.dispatch("setMarketFilters", filters);
      this.closeSlideUp();
    },
    handleSelectedTime(time) {
      if (time.value === "custom date") {
        this.timeCustom = true;
        return;
      }

      if (time.value === "all") {
        this.selectedStartDate = "";
        this.selectedEndDate = "";
        const filters = {
          ...this.$store.state.market_filters,
          start: "",
          end: "",
        };
        this.$store.dispatch("setMarketFilters", filters);
        this.selectedTime = time.value;
        this.closeSlideUp();

        return;
      }
      this.selectedTime = time.value;
      const filters = {
        ...this.$store.state.market_filters,
        byTime: time.value || "",
      };
      this.$store.dispatch("setMarketFilters", filters);
      this.closeSlideUp();
    },
    handleDateChange(value, type) {
      if (type === "start") {
        this.selectedStartDate = value;
      } else {
        this.selectedEndDate = value;
      }
    },
    handleSubmitDate() {
      const filters = {
        ...this.$store.state.market_filters,
      };
      if (this.selectedStartDate) {
        filters.start = this.selectedStartDate;
      }
      if (this.selectedEndDate) {
        filters.end = this.selectedEndDate;
      }
      this.selectedTime = "custom date";
      this.$store.dispatch("setMarketFilters", filters);
      this.closeSlideUp();
    },
    handleAction(type) {
      this.actionType = type;
      this.isSlideUpOpen = true;
    },
    handleLeague(type) {
      this.actionType = type;
      const isLeagueFilter = this.$store.state.isLeagueFilter;
      this.$store.dispatch("setLeagueFilter", !isLeagueFilter);
    },
    async getMarket() {
      var path = `${process.env.VUE_APP_URL_MARKETS}/${this.sport_id}`;
      try {
        const response = await fix.get(path, {
          headers: {
            accept: "application/json",
          },
        });
        this.markets = response.data;
        this.selectedMarket = response.data[0];
        // const filters = {
        //   ...this.$store.state.market_filters,
        // byLeagues: this.selectedCountry?.category_id,
        // tournament_id: this.selectedCountry?.tournament_id,
        // byTime: this.selectedTime,
        //   byMarket: response?.data[0]?.market_id,
        // };
        // this.$store.dispatch("setMarketFilters", filters);
      } catch (error) {
        // console.error("There was an error fetching the markets:", error);
      }
    },

    async getTournaments() {
      var path = `${process.env.VUE_APP_BASE_FIXTURE_URL}/tournaments/${this.sport_id}`;
      try {
        const response = await axios.get(path, {
          headers: {
            accept: "application/json",
          },
        });
        const countries = [...response.data];

        this.groupedByCountry(countries);
      } catch (error) {
        // console.error("There was an error fetching the markets:", error);
      }
    },
    getKey(match_id) {
      var prefix = "popular-games-" + match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "odd-id-" + prefix + "-");
    },
    setMarket(market) {
      this.market = market;
      this.market_id = market.market_id;
      this.market_name = market.market_name;
      if (market === -1) {
        this.$store.dispatch("setMarketID", 0);
        return;
      }
      this.selectedMarket = market;
      this.$store.dispatch("setMarketID", market.market_id);
    },
    getLeagueKey: function (index) {
      return Math.random()
        .toString(20)
        .replace("0.", "random-id-" + index + "-");
    },
    setLeague: function (league, leagueName = null) {
      if (league === -1) {
        this.selectedCountry = leagueName || "Leagues";
        this.$store.dispatch("setCompetitionID", 0);
        return;
      }
      this.competition_name = league.country_name + " - " + league.competition;
      this.league = league;
      this.competition_name = league.competition;
      this.selectedCountry =
        league.competition.length > 11
          ? league.competition.substring(0, 11)
          : league.competition;
      this.$store.dispatch("setCompetitionID", league.competition_id);
    },
    groupedByCountry: function (countries) {
      const priorityCountries = [
        "International Clubs",
        "England",
        "Spain",
        "Germany",
        "France",
        "Italy",
      ];

      const srlLeagues = [];
      const grouped = countries.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          if (item.category_name !== "Simulated Reality League") {
            acc[item.category_name] = [];
          }
        }
        if (item.category_name === "Simulated Reality League") {
          srlLeagues.push(item);
        } else {
          acc[item.category_name].push(item);
        }
        return acc;
      }, {});
      this.$store.dispatch("setSrlLeagues", srlLeagues);
      const sortedGrouped = Object.keys(grouped)
        .sort((a, b) => {
          if (priorityCountries.includes(a) && priorityCountries.includes(b)) {
            return priorityCountries.indexOf(a) - priorityCountries.indexOf(b);
          }
          if (priorityCountries.includes(a)) return -1;
          if (priorityCountries.includes(b)) return 1;
          return a.localeCompare(b);
        })
        .reduce((acc, country) => {
          acc[country] = grouped[country];
          return acc;
        }, {});
      this.$store.dispatch("setTournamentGrouped", sortedGrouped);
      const filters = {
        ...this.$store.state.market_filters,
        byLeagues: 0,
        tournament_id: 0,
      };
      this.$store.dispatch("setMarketFilters", filters);
    },

    isSimulatedRealityLeague: function (name) {
      return name === "Simulated Reality League";
    },
  },

  computed: {
    checkedTournament: function () {
      const res = this.$store.state.selectedTournament || [];
      return res;
    },
    leagues: function () {
      if (
        this.$store.state.leagues !== undefined &&
        this.$store.state.leagues !== null
      ) {
        return this.$store.state.leagues;
      }
      return this.$store.state.leagues;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
  },
  watch: {
    sport_id: function (newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) {
        this.selectedTime = "";
        this.selectedMarket = "";
        this.selectedStartDate = "";
        this.selectedEndDate = "";
        this.$store.dispatch("setLeagueFilter", false);
        this.fetchData();
        return;
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;

  padding: 4px 10px;
  border-bottom: 1px solid var(--border);
  margin-bottom: 8px;
  gap: 8px;
  width: 100%;
}

.filter > button {
  width: 33%;
  border-radius: 4px;
  background: var(--box-bg);

  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  color: var(--sub-text-color);
  span {
    color: var(--sub-text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
      fill: var(--text-color);
    }
  }
}

.tablinks {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
}
.active {
  border-bottom: 1px solid #a31212;
}
.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 40vh;
}

.filter_contents {
  padding-bottom: 7rem;
  width: 100%;
}

.filter_contents p {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
  display: flex;
  padding: 16px 20px;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  svg {
    cursor: pointer;
  }
}

.item {
  display: flex;
  padding: 16px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--border);
  cursor: pointer;

  & > span {
    color: var(--text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.date-picker {
  padding: 15px 10px 10px !important;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  border: 1px solid #d1cfcf;
  color: #0e0d0d !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  box-sizing: border-box;
  margin: 8px 0;
}

.custom-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
  }
}
.start-date {
  width: 50%;
  position: relative;
  label {
    position: absolute;
    top: 0px;
    left: 13px;
  }
}
input:focus {
  outline: none;
  border: 1px solid #cc1717;
}

.accordion {
  /* outline: 1px solid blue; */
  /* width: 80; */
  /* height: 100vh; */
  /* max-width: 80vh; */
  /* overflow-y: scroll; */
}
.actions {
  /* outline: 1px solid red; */
  display: flex;
  gap: 4px;
}
.accordion .head {
  background: #f7f7f7;
  border-bottom: 1px solid #e8e8e8;
  background: #f7f7f7;
  display: flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;

  color: var(--Light-Mode-Base-Base-900, #0e0d0d);

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.body_contents {
  /* outline: 1px solid red; */
}
.body_contents > div {
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  padding: 8px 30px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  /* outline: 4px solid red; */
  span {
    color: var(--Light-Mode-Base-Base-900, #0e0d0d);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    display: flex;
    align-items: center;
  }
}
.check {
  margin-left: 4px;
}
.light {
  color: var(--Light-Mode-Base-Base-600, #545151);
  font-family: "Satoshi Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}
.learn_more {
  /* outline: 12px solid red; */
}

.count {
  color: var(--text-color);
  font-size: 12px;
  background: var(--red);
  color: #fff !important;
  font-weight: 600;
  display: inline-block;
  padding: 4px 4px;
  height: 18px;
  width: 18px;
  border-radius: 50px;
  text-align: center;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
