<template>
  <div>
    <Banner />
    <div class="wrapper">
      <PopularGames></PopularGames>
      <Sports></Sports>
      <div id="dp" class="stick">
        <div class="highlight-box date-filter">
          <section class="bets-menu">
            <div :class="{ active: isActiveTab(1) }" @click="setHighlight">
              <span> {{ $t("highlights") }}</span>
            </div>
            <div :class="{ active: isActiveTab(2) }" @click="setBoosted">
              <span> {{ $t("boosted") }}</span>
            </div>
            <div :class="{ active: isActiveTab(3) }" @click="setUpcoming">
              <span> {{ $t("upcoming") }}</span>
            </div>
            <div :class="{ active: isActiveTab(4) }" @click="setTop10">
              <span> {{ $t("top10") }}</span>
            </div>
            <div
              :class="{ active: isActiveTab(5) }"
              @click="setSrl"
              v-show="ifSoccer"
            >
              <span>SRL</span>
            </div>
          </section>
        </div>
        <SportFilters />
      </div>
      <div v-if="isLeagueFilter">
        <LeagueSelector :groupedByCountry="groupedByCountry" />
        <div class="actions">
          <div>
            <ChopbetButton @click="handleReset" variant="outline">
              {{ $t("reset") }}
            </ChopbetButton>
            <ChopbetButton
              @click="handleApply"
              variant="primary"
              :disabled="checkedTournament.length > 5"
            >
              {{ $t("apply") }}
            </ChopbetButton>
          </div>
        </div>
      </div>
      <div v-else>
        <Games
          v-bind:highlights="highlights"
          v-bind:boosted="boosted"
          v-bind:upcoming="upcoming"
          v-bind:all="all"
          v-bind:srl="srl"
          v-bind:jackpot="jackpot"
          v-bind:today="today"
          v-bind:tomorrow="tomorrow"
          v-bind:sport="sport_id"
        >
        </Games>
      </div>
    </div>
  </div>
</template>

<script>
const Sports = () => import("./accordion/sports.vue");
const Games = () => import("./Games.vue");
const PopularGames = () => import("./popularGames/PopularGames.vue");
// import fixture from "@/services/fixtures";
import Banner from "../components/banner/Banner.vue";
import jQuery from "jquery";
import SportFilters from "./ui/SportFilters.vue";
import LeagueSelector from "./ui/LeagueSelector.vue";
import ChopbetButton from "./ui/ChopbetButton.vue";

const $ = jQuery;

export default {
  name: "Sport",
  components: {
    PopularGames,
    Sports,
    Games,
    Banner,
    SportFilters,
    LeagueSelector,
    ChopbetButton,
  },
  data: function () {
    return {
      highlights: this.$store.state.fixture_filter === 1,
      boosted: this.$store.state.fixture_filter === 2,
      upcoming: this.$store.state.fixture_filter === 3,
      all: this.$store.state.fixture_filter === 4,
      srl: this.$store.state.fixture_filter === 5,
      tomorrow: false,
      today: false,
      sub_page: "",
      mqttClient: "",
      homePagePopularGames: [
        // {
        //   providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
        //   gameID: "aviator",
        //   gameName: "Aviator",
        //   image: "/img/home/Aviator.jpg",
        //   crashGameUrl: "aviator",
        // },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
          crashGameUrl: "aviator",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_BOOMING),
          gameID: "657c5fe0a8abcd000146d9ab",
          gameName: "Ronaldinho Spins",
          image:
            "https://yard.gcsd.io/assets/games/657c5fe0a8abcd000146d9ab/v1.0.27/logos/en/mobile.png",
          crashGameUrl: "ronaldinho-spins",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SMART),
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_STP),
          gameID: "1",
          gameName: "Comet",
          image: "/img/home/CometCrash.jpg",
          crashGameUrl: "comet",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_ELBET),
          gameID: "rocketman",
          gameName: "RocketMan",
          image: "/img/home/Rocketman.jpg",
          crashGameUrl: "rocketman",
        },
        // {
        //   providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_STP),
        //   gameID: "5",
        //   gameName: "Monkey Bizniz",
        //   image: "/img/home/Monkey.png",
        //   crashGameUrl: "monkeybizniz",
        // },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_ELBET),
          gameID: "FootballX",
          gameName: "FootballX",
          image: "/img/home/FootballX.png",
          crashGameUrl: "footballx",
        },
        // {
        //   providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_STP),
        //   gameID: "9",
        //   gameName: "Street Dice",
        //   image: "/img/home/Dice.png",
        //   crashGameUrl: "streetdice",
        // },
      ],
    };
  },

  watch: {
    fixture_filter() {
      this.highlights = this.$store.state.fixture_filter === 1;
      this.boosted = this.$store.state.fixture_filter === 2;
      this.upcoming = this.$store.state.fixture_filter === 3;
      this.all = this.$store.state.fixture_filter === 4;
      this.srl = this.$store.state.fixture_filter === 5;
    },
    isLeagueFilter() {
      // console.log(this.isLeagueFilter, "isLeagueFilter");
      this.isLeagueFilter = this.$store.state.isLeagueFilter;
    },
  },
  mounted() {
    this.$store.dispatch("setLeagueFilter", false);
  },
  computed: {
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },

    fixture_filter: function () {
      return this.$store.state.fixture_filter;
    },
    ifSoccer: function () {
      return this.$store.state.sport_id === 1;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    isLeagueFilter: function () {
      return this.$store.state.isLeagueFilter;
    },
    checkedTournament: function () {
      return this.$store.state.selectedTournament;
    },
    groupedByCountry: function () {
      return this.$store.state.tournamentGrouped;
    },
  },
  methods: {
    isActiveTab(tab) {
      const res = this.fixture_filter === tab;
      return res;
      // return this.$store.state.isActiveTab;
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },
    setHighlight: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 1);
    },
    setBoosted: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 2);
    },
    setUpcoming: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 3);
    },
    setTop10: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 4);
    },
    setSrl: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 5);
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
    handleReset() {
      const filters = {
        ...this.$store.state.market_filters,
        byLeagues: 0,
        tournament_id: 0,
      };
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setMarketFilters", filters);
      this.$store.dispatch("setSelectedTournament", []);
    },
    handleApply() {
      const filters = {
        ...this.$store.state.market_filters,
        byLeagues: this.checkedTournament.join(",") || 0,
        tournament_id: this.checkedTournament.join(",") || 0,
      };
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setMarketFilters", filters);
    },
  },

  beforeDestroy: function () {
    this.$store.dispatch("setLeagueFilter", false);
  },
};

window.addEventListener("scroll", () => {
  //var viewportOject = $('#infoY').offset().top - 50;
  var scrollPos = $(document).scrollTop();
  if (scrollPos >= 150) {
    // $("#dp").addClass("stick");
    //alert("I am here");
  } else {
    // $("#dp").removeClass("stick");
  }
});
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
}

.bets-menu .active {
  border-bottom: solid var(--red) 1px;
}

.bets-menu .active span {
  color: var(--text-color) !important;
}

.bets-menu {
  font-size: 12px;
  white-space: nowrap;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.bets-menu > div {
  padding: 8px 0;
  font-weight: 500;
  margin: 3px 0;
  cursor: pointer;
  text-align: center;
}

.bets-menu > div a {
  color: var(--text-color);
}
.bets-menu::-webkit-scrollbar {
  display: none;
}
span {
  font-weight: 700;
}

.date-filter {
  padding: 4px 20px;
  border-top: 1px var(--border) solid;
  border-bottom: 1px var(--border) solid;
}

.bets-menu span {
  color: #8c8787;
  text-decoration: none;
  /* outline: 1px solid red; */
}

.bets-menu a:not([href]),
a:not([href]):hover {
  text-decoration: none;
  color: #8c8787;
}

.actions {
  gap: 4px;
  position: sticky;
  z-index: 20;
  bottom: 0rem;
  left: 0;
  background-color: var(--background-color);
  padding: 0.4rem 15px;
  padding-bottom: 0.5rem;
}
.actions > div {
  display: flex;
}
</style>
